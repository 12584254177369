import { deleteRequest, get, publicPost, put } from "../helpers/apihelpers";

export const CreateRoleService=async(data)=>{
    try {
        const result = await publicPost(`api/role/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateRoleService=async(data,id)=>{
    try {
        const result = await put(`api/role/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetRoleService=async(data)=>{
    try {
        const result = await get(`api/role/get`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteRoleService=async(id)=>{
    try {
        const result = await deleteRequest(`api/role/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}