import { Box } from '@mui/system'
import React,{useState} from 'react'
import axios from 'axios'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';

function Support() {

  const [report_data, setreport_data] = useState()

  const authHeader = {headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0MDA3N2Y3YzAyNWExZWZjZGJmYmY4ZiIsIm5hbWUiOiJLcmlzaG5hIFByYXNhZCBQSyIsInBob25lIjoiNzQxMTc0NzEzMyIsImVtYWlsIjoia3Jpc2huYS5wcmFzYWRAZmlkZWxpdHVzY29ycC5jb20iLCJpbWFnZSI6InVwbG9hZHMvcHJvZmlsZV9waG90by9XaGF0c0FwcCBJbWFnZSAyMDIzLTA0LTE4IGF0IDEwLjEzLjM0IEFNXzE2ODE3OTMwNDcyMTcuanBlZyIsImVtcGxveWVlX2lkIjoiZmNwbF8yNDAiLCJvZmZpY2lhbF9lbWFpbCI6ImtyaXNobmEucHJhc2FkQGZpZGVsaXR1c2NvcnAuY29tIiwib2ZmaWNpYWxfcGhvbmUiOiI5NjExMzkxOTg2IiwiaXNfdmVyaWZpZWQiOnRydWUsImlzX3BhcnRpYWxseV92ZXJpZmllZCI6dHJ1ZSwic2VudF9mb3JfcmV2aWV3Ijp0cnVlLCJhcHBfYWNjZXNzIjp0cnVlLCJpc19hY3RpdmUiOnRydWUsImV4aXRfZm9ybSI6ZmFsc2UsImJyYW5jaCI6eyJfaWQiOiI2M2Y2OTVlM2RlOTNkNDE1YzMyNWRmMjYiLCJkaXN0cmljdF9pZCI6IjYzZjY5NWQ0ZGU5M2Q0MTVjMzI1ZGYwZSIsImJyYW5jaF9uYW1lIjoiQmFuc2hhbmtyaSIsImRpc3BsYXlfbmFtZSI6IkJhbnNoYW5rcmkiLCJjcmVhdGVkQXQiOiIyMDIzLTAyLTIyVDIyOjIzOjMxLjU1M1oiLCJ1cGRhdGVkQXQiOiIyMDIzLTAyLTIyVDIyOjIzOjMxLjU1M1oiLCJfX3YiOjB9LCJkZXNpZ25hdGlvbl9pZCI6eyJkZXNpZ25hdGlvbl9uYW1lIjoiVHJhbnNhY3Rpb24gTWFuYWdlciIsImlzQWN0aXZlIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIzLTAyLTIyVDIyOjA0OjE1Ljc1OFoiLCJ1cGRhdGVkQXQiOiIyMDIzLTAyLTIyVDIyOjA0OjE1Ljc1OFoiLCJpZCI6IjYzZjY5MTVmNDViMTRiOGJmYWI2NmYwMiJ9LCJkZXBhcnRtZW50X2lkIjpbeyJkZXBhcnRtZW50X25hbWUiOiJTb2Z0d2FyZSBUZWFtIiwiaXNBY3RpdmUiOnRydWUsImNyZWF0ZWRBdCI6IjIwMjMtMDItMjJUMjI6MDE6MjkuMTUxWiIsInVwZGF0ZWRBdCI6IjIwMjMtMDItMjJUMjI6MDE6MjkuMTUxWiIsImlkIjoiNjNmNjkwYjg0NWIxNGI4YmZhYjY2ZTRiIn1dLCJkb2IiOiIyMDIzLTAzLTAyIiwiZG9qIjoiMjAyMy0wMy0wMiIsImRvZSI6IiIsImluY2VudGl2ZV9lbmFibGVkIjpmYWxzZSwicm9sZXMiOlsiYWRtaW4iLCJjb250cm9sbGVyIiwiYmRfdXNlciJdLCJpYXQiOjE2ODU0NDk5NDN9.abH1p8EA170ylB61pheIvCPWSvX0kxkP7Hlpc8ms8GY`
  }}

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  
  const [sheets_list, setsheets_list] = useState()
  const [sheets_list1, setsheets_list1] = useState()
  const [datas, setdatas] = useState()
  const [datas1, setdatas1] = useState()

  function submitData(){
    axios.get(`http://ec2-13-127-174-172.ap-south-1.compute.amazonaws.com:7000/api/crm_report/filter_report?type=${'contact'}&department_id=${'63f690c445b14b8bfab66e5b'}&employee_id=${''}&from_date=${''}&to_date=${''}`, authHeader)
        .then((res) => {
          setreport_data(res.data.data)
          // console.log('res.data here', res.data)
        }).catch((err) => {
          // console.log('err.data here', err.response.data)
        })
    }



  async function downloadreport(v) {
    

    const sheets_list_1 = ['Contacts']
    const datas_wb_1 = []

    let contact_data_list = []


      return new Promise(async (resolve, reject) => {  
            for (let i = 0; i < report_data?.contacts?.length; i++) {
              // console.log('iam here in', i)
              let d = report_data.contacts[i]
              let obj = {
                contact_owner: d?.lead_owner?.name,
                company_name: d?.company_name,
                contact_name: d?.contact_name,
                phone: d?.phone,
                email: d?.email,
                designation: d?.designation,
                contact_source: d?.contact_source,
                website: d?.website,
                industry: d?.industry,
                company_phone: d?.company_phone,
                company_email: d?.company_email,
                secondary_contact_person: d?.secondary_contact_person,
                secondary_contact_phone: d?.secondary_contact_phone,
                secondary_contact_designation: d?.secondary_contact_designation,
                status: d?.status,
                street: d?.street,
                city: d?.city,
                state: d?.state,
                country: d?.country,
                pin_code: d?.pin_code,
                description: d?.description,
                isConverted: d?.isConverted,
                _id: d?._id,
              }
              contact_data_list.push(obj)
            }
            datas_wb_1.push(contact_data_list)

          resolve({sheets_list_1, datas_wb_1 })

          })

          
       



  }

  async function print_report(v) {
    // console.log('sheets_list 11', sheets_list1, 'datas1', datas1, 'sheets_list', sheets_list, 'datas', datas)

    await downloadreport(v)
      .then((res) => {
        

          const wb1 = XLSX.utils.book_new();
          for (let i = 0; i < res?.sheets_list_1?.length; i++) {
            let t = XLSX.utils.json_to_sheet(res?.datas_wb_1[i])
            XLSX.utils.book_append_sheet(wb1, t, res?.sheets_list_1[i]);
          }

          const excelBuffer1 = XLSX.write(wb1, { bookType: 'xlsx', type: 'array' });
          const data1 = new Blob([excelBuffer1], { type: fileType });
          FileSaver.saveAs(data1, `CRM REPORT Data (${moment(new Date()).format('lll')})`);
      })






  }

  
    console.log("report_data",report_data)
  

  return (
    <Box className="mt-5"> 
 
       <span onClick={submitData} className='text-[13px] m-4 my-4 cursor-pointer mt-10 p-3 bg-cyan-500'>CRM REPORT DOWNLAOD</span>
       
       <h6 onClick={print_report}>Print Report</h6>
    </Box>
  )
}

export default Support