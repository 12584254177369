import { LOG_IN,LOG_OUT,ERROR, PROFILEPIC } from "../actionTypes/authActions";

export function LogInAction(result){
   return{
    type:LOG_IN,
    payload:result
   }
}

export function LogOutAction(){
    return{
     type:LOG_OUT,
    }
}

export function ErrorAction(v){
   return{
      type:ERROR,
      payload:v
   }
}

export function ProfilePicAction(v){
   return{
      type:PROFILEPIC,
      payload:v
   }
}

