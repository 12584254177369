import React, {useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {FiLogOut} from 'react-icons/fi';
import { btnColor } from '../../constants/themeConstants';
import { LogOutAction, ProfilePicAction } from '../../redux/actions/authAction';
import { Avatar } from 'antd';
import {BiCamera} from 'react-icons/bi';
import { UploadProfilepic } from '../../services/AuthServices';


function Profile() {
  const navigate = useNavigate()

  const user = useSelector(state=>state.Auth)
  const inputFile = useRef(null) 





  const dispatch = useDispatch()



  function logoutfunction(){
    dispatch(LogOutAction())
    navigate("/login",{ replace: true })
  }

  const onButtonClick = () => {
    inputFile.current.click();
  } 


  async function handlechange(e){
    let data = {
       image :e?.target?.files[0]
    }
    const response = await UploadProfilepic(data)
    dispatch(ProfilePicAction(response?.data?.datas?.photo))
  }


  

  return (
   
    <div className='ml-2 pt-14'>
      <input type='file' accept="image" id='file' ref={inputFile} onChange={handlechange} style={{display: 'none'}}/>

      <div className='relative  height-100 w-24'>
      <Avatar size={100} className='mb-4 relative'  src={user?.image === null ? 'https://fidecrmfiles.s3.amazonaws.com/noprofile.png' : `${process.env.REACT_APP_BACKEND_IMAGE_URL}${user.image}`}/>
      <BiCamera size={24} onClick={onButtonClick} className={`absolute bottom-4 right-1 color-[#cfcfcf] cursor-pointer`} />
      </div>

      <h6 className='text-[13px] font-[700]'>{user?.name}</h6>
      <h6 className='text-[11.5px] font-[500] mb-0'>{user?.official_email} [{user?.employee_id}]</h6>
      <h6 className='text-[11.5px] font-[500]'>{user?.department_id[0]?.department_name}</h6>







      

      <div className='flex items-center absolute bottom-10 cursor-pointer' onClick={logoutfunction}>
        <FiLogOut color={btnColor} />
        <h6 className={`ml-2 text-[12px] text-[${btnColor}] font-[700]`}>Logout</h6>
      </div>
    </div>
  )
}

export default Profile