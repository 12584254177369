import React from 'react'
import { useNavigate } from 'react-router-dom'
import {IoChevronBackCircleOutline} from 'react-icons/io5'
function GoBack() {
  
  const navigator = useNavigate()
  return (
    <div onClick={()=>navigator(-1)} className='flex text-gray-800 items-center my-2 cursor-pointer'>
        <IoChevronBackCircleOutline />
        <span className='ml-1 text-[11px] font-[400]'>Go Back</span>
    </div>
  )
}

export default GoBack