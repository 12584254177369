import { deleteRequest, get, publicPost, put } from "../helpers/apihelpers";

export const CreateDesignationService=async(data)=>{
    try {
        const result = await publicPost(`api/designation/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateDesignationService=async(data,id)=>{
    try {
        const result = await put(`api/designation/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetDesignationService=async(data)=>{
    try {
        const result = await get(`api/designation/get`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteDesignationService=async(id)=>{
    try {
        const result = await deleteRequest(`api/designation/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}