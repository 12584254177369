import { ERROR, LOG_IN, LOG_OUT, PROFILEPIC } from "../actionTypes/authActions";

const initialState = {
    isAuthenticated:false,
    userId:"",
    user:{},
    error:{type:'',message:'',visible:false}
}


export default function AuthReducer(state = initialState,action){
    const payload = action.payload;
    // console.log('payload',payload)
    switch (action.type) {
        case LOG_IN:
            if(payload?.token){
                state.isAuthenticated = true;
            }
            return {...state, ...payload};
        case LOG_OUT:
            return{
                ...state,
                isAuthenticated:false,
                access:"",
                token:"",
                userid:""
            }  
        case ERROR:
            if(payload?.type === 'success'){
                return {...state,error:{...payload}}
            }else if(payload?.type === 'error'){
                return {...state,error:{...payload}}
            }else if(payload?.type === 'warning'){
                return {...state,error:{...payload}}
            }else{
                return{...state,error:{...payload}}
            }  
        case PROFILEPIC:
            return {...state,image:action.payload}            
        default:
            return state
    }
}